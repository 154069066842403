<template>
  <!-- full page background color -->
  <v-card fluid flat tile min-height="92vh" class="back mb-n15 pa-6  px-md-15">
    <!-- to center the card in different media screen -->
    <v-row class="justify-center">
      <!-- loader before the data is ready -->
      <v-col v-if="loader" xl="6" lg="8" md="10">
        <v-skeleton-loader
          class="mb-3"
          type="card-heading, list-item-two-line"
        />
        <v-skeleton-loader class="mb-3" type="article, actions" />
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-skeleton-loader type="button" />
        </div>
      </v-col>
      <!-- to wrap in the column and leave space both side -->
      <v-col v-else xl="6" lg="8" md="10">
        <!-- Form Header shows Form name and desc-->
        <v-card class="customForm" flat>
          <!-- card don't allow CSS border that is required to identify when focus -->
          <div class="topborder">
            <!-- Textfield for form name -->
            <v-card-title class="pb-0 pt-0">
              <v-text-field
                v-model="formName"
                readonly
                class="h2text mb-0"
                single-line
                hide-details
              />
            </v-card-title>
            <!-- Textfield for form desc -->
            <v-card-text>
              <v-text-field
                v-model="formDesc"
                readonly
                placeholder="Form Description"
                single-line
                hide-details
              />
            </v-card-text>
          </div>
        </v-card>

        <v-tabs v-model="tab" class="mt-2" centered color="primary">
          <v-tab v-if="responses.length > 0" class="text-capitalize"
            >Earlier Responses</v-tab
          >
          <v-tab class="text-capitalize">New Response</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparent">
          <!-- earlier responses -->
          <v-tab-item v-if="responses.length > 0" class="transparent">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(res, i) in responses"
                :key="i"
                class="translucent"
              >
                <v-expansion-panel-header class="white">
                  Response submitted on
                  {{ new Date(res.createdOn).toISOString().substr(0, 10) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="transparent">
                  <div v-for="(question, i) in questionSet" :key="i">
                    <!-- the card having the fields -->
                    <v-card flat class="my-2">
                      <v-card-title class="text-h6 font-weight-regular pb-0">
                        {{ question.name }}
                        <span class="red--text" v-if="question.required"
                          >*</span
                        >
                      </v-card-title>
                      <!-- If the datatype is text -->
                      <v-card-text
                        class="py-0"
                        v-if="question.datatype == 'text'"
                      >
                        <v-row class="my-0 py-0">
                          <v-col cols="8" class="py-0">
                            <v-text-field
                              v-model="res.data[i]"
                              :rules="question.required ? requiredRule : []"
                              class="text-body-2 pb-2"
                              placeholder="Your answer"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!-- If the datatype is mcq -->
                      <v-card-text
                        class="py-0"
                        v-if="question.datatype == 'mcq'"
                      >
                        <v-radio-group v-model="res.data[i]" row>
                          <v-radio
                            class="text-body-2"
                            v-for="(option, j) in question.options"
                            :key="j"
                            :label="option"
                            :value="option"
                          />
                        </v-radio-group>
                      </v-card-text>
                      <!-- If the datatype is checkbox -->
                      <v-card-text
                        class="py-0 d-flex"
                        v-if="question.datatype == 'checkbox'"
                      >
                        <v-checkbox
                          v-model="res.data[i]"
                          class="pr-4 text-body-2"
                          v-for="(option, j) in question.options"
                          :key="j"
                          :label="option"
                          :value="option"
                        />
                      </v-card-text>
                      <!-- If the datatype is dropdown -->
                      <v-card-text
                        class="py-0 d-flex"
                        v-if="question.datatype == 'dropdown'"
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="my-2 text-body-2"
                              color="grey"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ res.data[i] == "" ? "Select" : res.data[i] }}
                              <v-icon right>mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(option, j) in question.options"
                              :key="j"
                              @click="res.data[i] = option"
                            >
                              <v-list-item-title>{{
                                option
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-card-text>
                      <!-- If the datatype is file -->
                      <v-card-text
                        class="py-0"
                        v-if="question.datatype == 'file'"
                      >
                        <v-row class="my-0 py-0">
                          <v-col cols="8" class="py-0">
                            <v-file-input
                              class="text-body-2 pb-2"
                              :rules="question.required ? requiredRule : null"
                              :accept="
                                question.allowfileType
                                  ? question.filetype
                                  : null
                              "
                              show-size
                              :placeholder="
                                res.data[i]
                                  ? res.data[i].attachmentname
                                  : 'Add File'
                              "
                              @change="onFileSelect(i, $event)"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!-- If the datatype is date -->
                      <v-card-text
                        class="py-0"
                        v-if="question.datatype == 'date'"
                      >
                        <v-row class="my-0 py-0">
                          <v-col cols="8" class="py-0">
                            <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="res.data[i]"
                                  :rules="question.required ? requiredRule : []"
                                  class="text-body-2"
                                  placeholder="Your Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker
                                v-model="res.data[i]"
                                color="primarygrad"
                              />
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!-- If the datatype is time -->
                      <v-card-text
                        class="py-0"
                        v-if="question.datatype == 'time'"
                      >
                        <v-row class="my-0 py-0">
                          <v-col cols="8" class="py-0">
                            <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="res.data[i]"
                                  :rules="question.required ? requiredRule : []"
                                  class="text-body-2"
                                  placeholder="Your Time"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <v-time-picker
                                v-model="res.data[i]"
                                ampm-in-title
                                color="primarygrad"
                              />
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn class="my-2" text color="red" @click="remove(res)"
                      >DELETE</v-btn
                    >
                    <v-btn
                      class="primarygrad my-2"
                      dark
                      depressed
                      @click="update(res)"
                      >UPDATE</v-btn
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <!-- new response -->
          <v-tab-item>
            <div v-for="(question, i) in questionSet" :key="i">
              <!-- the card having the fields -->
              <v-card flat class="my-2">
                <v-card-title class="text-h6 font-weight-regular pb-0">
                  {{ question.name }}
                  <span class="red--text" v-if="question.required">*</span>
                </v-card-title>
                <!-- If the datatype is text -->
                <v-card-text class="py-0" v-if="question.datatype == 'text'">
                  <v-row class="my-0 py-0">
                    <v-col cols="8" class="py-0">
                      <v-text-field
                        v-model="answerSet[i]"
                        :rules="question.required ? requiredRule : []"
                        class="text-body-2 pb-2"
                        placeholder="Your answer"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- If the datatype is mcq -->
                <v-card-text class="py-0" v-if="question.datatype == 'mcq'">
                  <v-radio-group v-model="answerSet[i]" row>
                    <v-radio
                      class="text-body-2"
                      v-for="(option, j) in question.options"
                      :key="j"
                      :label="option"
                      :value="option"
                    />
                  </v-radio-group>
                </v-card-text>
                <!-- If the datatype is checkbox -->
                <v-card-text
                  class="py-0 d-flex"
                  v-if="question.datatype == 'checkbox'"
                >
                  <v-checkbox
                    v-model="answerSet[i]"
                    class="pr-4 text-body-2"
                    v-for="(option, j) in question.options"
                    :key="j"
                    :label="option"
                    :value="option"
                  />
                </v-card-text>
                <!-- If the datatype is dropdown -->
                <v-card-text
                  class="py-0 d-flex"
                  v-if="question.datatype == 'dropdown'"
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="my-2 text-body-2"
                        color="grey"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ answerSet[i] == "" ? "Select" : answerSet[i] }}
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(option, j) in question.options"
                        :key="j"
                        @click="answerSet[i] = option"
                      >
                        <v-list-item-title>{{ option }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-text>
                <!-- If the datatype is file -->
                <v-card-text class="py-0" v-if="question.datatype == 'file'">
                  <v-row class="my-0 py-0">
                    <v-col cols="8" class="py-0">
                      <v-file-input
                        class="text-body-2 pb-2"
                        :rules="question.required ? requiredRule : null"
                        :accept="
                          question.allowfileType ? question.filetype : null
                        "
                        show-size
                        :placeholder="
                          answerSet[i]
                            ? answerSet[i].attachmentname
                            : 'Add File'
                        "
                        @change="onFileSelect(i, $event)"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- If the datatype is date -->
                <v-card-text class="py-0" v-if="question.datatype == 'date'">
                  <v-row class="my-0 py-0">
                    <v-col cols="8" class="py-0">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="answerSet[i]"
                            :rules="question.required ? requiredRule : []"
                            class="text-body-2"
                            placeholder="Your Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="answerSet[i]"
                          color="primarygrad"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- If the datatype is time -->
                <v-card-text class="py-0" v-if="question.datatype == 'time'">
                  <v-row class="my-0 py-0">
                    <v-col cols="8" class="py-0">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="answerSet[i]"
                            :rules="question.required ? requiredRule : []"
                            class="text-body-2"
                            placeholder="Your Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-time-picker
                          v-model="answerSet[i]"
                          ampm-in-title
                          color="primarygrad"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn class="primarygrad my-2" dark depressed @click="save()"
                >SUBMIT</v-btn
              >
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-snackbar v-model="moreSpace">
      File must be less than 10MB
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="moreSpace = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="formatMismatch">
      This type of file is not expected
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="formatMismatch = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const _ = require("lodash");
export default {
  data() {
    return {
      tab: 0,
      formId: "",
      responseId: "",
      contactId: "",
      formName: "",
      formDesc: "",
      questionSet: [],
      loader: true,
      answerSet: [],
      responses: [],
      datemenu: false,
      timemenu: false,
      moreSpace: false,
      formatMismatch: false,
      datemenuNew: false,
      timemenuNew: false,
      requiredRule: [(v) => !!v || "This is a required question"],
    };
  },
  async created() {
    this.formId = this.$route.params.formId;
    this.contactId = this.$route.params.contactId;
    await this.getForm(this.formId);
    await this.checkFormResponse(this.contactId);
    this.loader = false;
  },
  methods: {
    async getForm(id) {
      // getting the details of the form
      this.$http
        .get(`${this.$store.state.ENDPOINT}/forms/${id}`)
        .then((res) => {
          // setting the details of the form
          this.formName = res.data.name;
          this.formDesc = res.data.desc;
          this.questionSet = res.data.fields;
          this.prepareAns(this.questionSet);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        }); //if error occurs
    },
    async checkFormResponse(contactId) {
      this.$http
        .get(`${this.$store.state.ENDPOINT}/contacts/${contactId}/formResponse`)
        .then((res) => {
          this.responses = res.body.filter((fr) => fr.formId == this.formId);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        }); //if error occurs
    },
    prepareAns(questionSet) {
      this.answerSet = new Array(questionSet.length);
      for (var i = 0; i < questionSet.length; i++) {
        if (questionSet[i].datatype == "checkbox") this.answerSet[i] = [];
        else if (questionSet[i].datatype == "mcq")
          this.answerSet[i] = questionSet[i].options[0];
        else if (questionSet[i].datatype == "file") this.answerSet[i] = null;
        else this.answerSet[i] = "";
      }
      this.response.answerSet = this.answerSet;
    },
    onFileSelect(i, file) {
      if (file) {
        var mime_type = file.type;
        // var fileName = file.name;
        var size = file.size;
        console.log("file: ", file);
        console.log("this.questionSet[i]: ", this.questionSet[i]);
        console.log("this.answerSet[i]: ", this.answerSet[i]);
        // More than 10MB
        if (size / (1024 * 1024) > 10) {
          this.moreSpace = true;
          this.answerSet[i] = null;
        }
        // Not allowed file type
        else if (
          this.questionSet[i].allowfileType &&
          !_.includes(this.questionSet[i].filetype, mime_type)
        ) {
          this.formatMismatch = true;
          this.answerSet[i] = null;
        } else {
          this.answerSet[i] = file;
        }
        console.log("this.answerSet[i]: ", this.answerSet[i]);
      }
    },
    valiadtion(answerSet) {
      for (var i = 0; i < this.questionSet.length; i++) {
        if (
          this.questionSet[i].required &&
          (answerSet[i] == "" || answerSet[i] == [])
        ) {
          this.$swal({
            type: "warning",
            text: "Please fill all mandatory questions",
          });
          return false;
        } else if (
          this.questionSet[i].required &&
          (answerSet[i] == null || answerSet[i] == {})
        ) {
          this.$swal({
            type: "warning",
            text: "Please check all mandatory attachments",
          });
          return false;
        }
      }
      // for (i=0;i<this.questionSet.length;i++)
      // {
      //     if(this.questionSet[i].datatype =='file' )
      //         {
      //             console.log("this.answerSet[i]: ",answerSet[i])
      //             var formData = new FormData();
      //             formData.append("file", answerSet[i]);
      //             formData.append("type", this.questionSet[i].filetype);
      //             formData.append("companyId", this.$store.state.companyDetails.id);
      //             var config = {
      //                 headers: {
      //                     "Content-Type": "multipart/form-data",
      //                     authorization: this.$store.state.user.token,
      //                 },
      //             };
      //             var attachment = await this.$http.post(
      //             `${this.$store.state.ENDPOINT}/attachments/upload`,
      //             formData,
      //             config
      //             );
      //             attachment = attachment.body;
      //             answerSet[i]={
      //                     attachmentId: attachment.id,
      //                     attachmentname: attachment.name,
      //                 }
      //         }
      // }
      return true;
    },
    async update(res) {
      if (this.valiadtion(res.data)) {
        this.$http
          .patch(`${this.$store.state.ENDPOINT}/formResponses/${res.id}`, res) //storing the formResponse in the database
          .then((res) => {
            // console.log("Form Response: ",res )
            this.$swal({
              type: "success",
              text: "Form Response Updated",
            }).then((yes) => {
              // after storing the message is displayed
              if (yes.value) {
                // on user click redirected to forms page
                this.$router.go(-1);
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log("Error in storing formResponse", err);
          }); // in case of error in storing the formResponse
      }
    },
    async save() {
      for (var i = 0; i < this.questionSet.length; i++) {
        if (
          this.questionSet[i].required &&
          (this.answerSet[i] == "" || this.answerSet[i] == [])
        )
          return this.$swal({
            type: "warning",
            text: "Please fill all mandatory questions",
          });
        else if (
          this.questionSet[i].required &&
          (this.answerSet[i] == null || this.answerSet[i] == {})
        )
          return this.$swal({
            type: "warning",
            text: "Please check all mandatory attachments",
          });
      }

      // for attachment
      // for (i=0;i<this.questionSet.length;i++)
      // {
      //     if(this.questionSet[i].datatype =='file' )
      //         {
      //             console.log("this.answerSet[i]: ",this.answerSet[i])
      //             var formData = new FormData();
      //             formData.append("file", this.answerSet[i]);
      //             formData.append("type", this.questionSet[i].filetype);
      //             formData.append("companyId", this.$store.state.companyDetails.id);
      //             var config = {
      //                 headers: {
      //                     "Content-Type": "multipart/form-data",
      //                     authorization: this.$store.state.user.token,
      //                 },
      //             };
      //             var attachment = await this.$http.post(
      //             `${this.$store.state.ENDPOINT}/attachments/upload`,
      //             formData,
      //             config
      //             );
      //             attachment = attachment.body;
      //             this.answerSet[i]={
      //                     attachmentId: attachment.id,
      //                     attachmentname: attachment.name,
      //                 }
      //         }
      // }

      var payload = {};
      payload.data = this.answerSet; //AnswerSet
      payload.questionSet = this.questionSet;
      payload.createdOn = new Date().toISOString(); //created Date
      payload.formsId = this.formId; //form Id
      payload.contactId = this.contactId; // contact Id
      payload.accountsId = this.$store.state.user.id; //account ID
      console.log("payload", payload);

      // storing new formResponse
      this.$http
        .post(`${this.$store.state.ENDPOINT}/formResponses`, payload) //storing the formResponse in the database
        .then((res) => {
          // console.log("Form Response: ",res )
          this.$swal({
            type: "success",
            text: "New Form Response is submitted",
          }).then((yes) => {
            // after storing the message is displayed
            if (yes.value) {
              // on user click redirected to forms page
              this.$router.go(-1);
            }
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log("Error in storing formResponse", err);
        }); // in case of error in storing the formResponse
    },
    async remove(res) {
      this.$swal({
        type: "warning",
        text: "Are you sure you want to Delete this response",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#f44336",
        confirmButtonText: "Yes, Remove it!",
      }).then((yes) => {
        if (yes.value) {
          this.$http
            .delete(`${this.$store.state.ENDPOINT}/formResponses/${res.id}`)
            .then((resp) => {
              var i = this.responses.indexOf(res);
              console.log(i);
              this.responses.splice(i, 1);
              this.$swal({
                type: "success",
                text: "Form Response is deleted",
              });
            })
            .catch((err) => {
              console.log("Error in deleting formResponse", err);
            });
        }
      });
    },
  },
};
</script>

<style>
/* .v-menu__content{
        max-height: none !important;
    } */
.customForm .v-text-field > .v-input__control > .v-input__slot:before {
  /* no underline always */
  border-color: inherit;
  border-style: none !important;
  border-width: thin 0 0;
}
.customForm .v-text-field > .v-input__control > .v-input__slot:after {
  /* no underline always */
  border-color: inherit;
  border-style: none !important;
  border-width: thin 0 0;
}
.topborder {
  /* top border like alert */
  border-top: 8px solid #31a848;
}
.onfocus {
  /* to highlight the card focused on */
  border-left: 6px solid #92df48;
}
</style>
<style scoped>
.h2text {
  font-size: 1.5em !important;
  font-weight: 400 !important;
}
.dottedLine {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
}
ul.styleNone {
  /* no bullet in list */
  list-style-type: none;
  padding-left: 0;
}
.translucent {
  background: #ffffff59 !important;
}
</style>
